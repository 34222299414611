var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "extra-component-echarts-demo" } }, [
    _vm._m(0),
    _c("div", { staticClass: "vx-row match-height" }, [
      _c("div", { staticClass: "vx-col w-full" }, [_c("echarts-bar-chart")], 1),
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [_c("echarts-line-chart")],
        1
      ),
      _c("div", { staticClass: "vx-col w-full" }, [_c("echarts-pie-chart")], 1),
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [_c("echarts-scatter-chart")],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2" },
        [_c("echarts-polar-chart")],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2" },
        [_c("echarts-radar-chart")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _vm._v("ECharts component for Vue.js. Read full documnetation "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/ecomfe/vue-echarts",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }