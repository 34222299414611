var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Radar Chart", "code-toggler": "" } },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("e-charts", {
            ref: "radar",
            attrs: { autoresize: "", options: _vm.radar, "auto-resize": "" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <e-charts autoresize :options=\"radar\" ref=\"radar\" auto-resize />\n</template>\n\n<script>\nimport ECharts from 'vue-echarts/components/ECharts'\nimport 'echarts/lib/component/tooltip'\nimport 'echarts/lib/component/legend'\nimport 'echarts/lib/chart/radar'\n\nexport default {\n  data() {\n    return {\n      radar:{\n            tooltip: {},\n            radar: {\n              indicator: [\n              {name: 'Attack', max: 20},\n              {name: 'Defensive', max: 20},\n              {name: 'Speed', max: 20},\n              {name: 'Power', max: 20},\n              {name: 'Endurance', max: 20},\n              {name: 'Agile', max: 20}\n            ]\n            },\n            series: [{\n              name: 'Ability value',\n              type: 'radar',\n              data: [{value: [19,9,18,16,16,20]}]\n            }]\n      },\n    }\n  },\n  components: {\n    ECharts\n  }\n}\n</script>\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }