var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Polar Chart", "code-toggler": "" } },
    [
      _c("span", [_vm._v("Dark Theme")]),
      _c("vs-switch", {
        model: {
          value: _vm.themeDark,
          callback: function ($$v) {
            _vm.themeDark = $$v
          },
          expression: "themeDark",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("e-charts", {
            ref: "bar",
            attrs: {
              options: _vm.polar,
              theme: _vm.themeDark ? "dark" : "default",
              "auto-resize": "",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <e-charts :options=\"polar\" ref=\"bar\" :theme=\"themeDark ? 'dark' : 'default'\" auto-resize />\n</template>\n\n<script>\nimport ECharts from 'vue-echarts/components/ECharts'\nimport 'echarts/lib/component/tooltip'\nimport 'echarts/lib/component/legend'\nimport 'echarts/lib/component/polar'\n\nimport 'echarts/theme/dark'\n\nexport default {\n  data() {\n    let data = []\n\n    for (let i = 0; i <= 360; i++) {\n      let t = i / 180 * Math.PI\n      let r = Math.sin(2 * t) * Math.cos(2 * t)\n      data.push([r, i])\n    }\n    return {\n      themeDark: false,\n      polar:{\n          legend: {\n            data: ['line']\n          },\n          polar: {\n            center: ['50%', '54%']\n          },\n          tooltip: {\n            trigger: 'axis',\n            axisPointer: {\n              type: 'cross'\n            }\n          },\n          angleAxis: {\n            type: 'value',\n            startAngle: 0\n          },\n          radiusAxis: {\n            min: 0\n          },\n          series: [\n            {\n              coordinateSystem: 'polar',\n              name: 'line',\n              type: 'line',\n              showSymbol: false,\n              data: data\n            }\n          ],\n          animationDuration: 2000\n      },\n    }\n  },\n  components: {\n    ECharts\n  }\n}\n</script>\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }