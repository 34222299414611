var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      staticClass: "mb-base",
      attrs: { title: "Bar Chart", "code-toggler": "" },
    },
    [
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("e-charts", {
            ref: "bar",
            attrs: {
              autoresize: "",
              options: _vm.bar,
              theme: "ovilia-green",
              "auto-resize": "",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <e-charts autoresize :options=\"bar\" ref=\"bar\" theme=\"ovilia-green\" auto-resize />\n</template>\n\n<script>\nimport ECharts from 'vue-echarts/components/ECharts'\nimport 'echarts/lib/component/tooltip'\nimport 'echarts/lib/component/legend'\nimport 'echarts/lib/chart/bar'\nimport theme from './theme.json'\n\nECharts.registerTheme('ovilia-green', theme)\n\nexport default {\n  data() {\n    return {\n      bar: {\n        legend: {},\n        tooltip: {},\n        dataset: {\n          // Provide data.\n          source: [\n            ['Product', '2015', '2016', '2017'],\n            ['Matcha Latte', ...this.randomize()],\n            ['Milk Tea', ...this.randomize()],\n            ['Cheese Cocoa', ...this.randomize()],\n            ['Walnut Brownie', ...this.randomize()]\n          ]\n        },\n        // Declare X axis, which is a category axis, mapping\n        // to the first column by default.\n        xAxis: { type: 'category' },\n        // Declare Y axis, which is a value axis.\n        yAxis: {},\n        // Declare several series, each of them mapped to a\n        // column of the dataset by default.\n        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]\n\n      },\n    }\n  },\n  methods: {\n    randomize() {\n      return [0, 0, 0].map(v => {\n        return Math.round(300 + Math.random() * 700) / 10\n      })\n    }\n  },\n  components: {\n    ECharts\n  }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }